import 'antd/dist/antd.css';
import React from 'react';
import './App.css';
import Content from './Content';
import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCb3GaTT0YG0WzWXExvLXWyJP8HCG3PdEc",
  authDomain: "virtualanniversary-471c6.firebaseapp.com",
  projectId: "virtualanniversary-471c6",
  storageBucket: "virtualanniversary-471c6.appspot.com",
  messagingSenderId: "259584434803",
  appId: "1:259584434803:web:c514178139145246440006"
};

firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <Content />
  );
}

export default App;
