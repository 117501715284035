import FlexLayout from "./FlexLayout";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Title from 'antd/lib/typography/Title';
import { Image } from 'antd';
import { Heap } from "ts-heap";

export type Media = {
  baseUrl: string,
  productUrl: string,
  width: number,
  height: number,
  date: Date,
}

type Props = {
  medias: Array<Media | string>,
  columnWidth: number,
  padding: number,
  loadingPosition: number,
  load: () => void,
  title: string,
  loadInterval: number,
  scrollInterval: number,
}

type WaterFallColumn = {
  items: Array<JSX.Element>,
  height: number,
}

const WaterFall = ({
  title,
  load,
  loadingPosition,
  medias,
  columnWidth,
  padding,
  loadInterval,
  scrollInterval,
}: Props) => {
  const containerRef = useRef<any>(null);

  const getColumnCount = useCallback(
    () => Math.max(Math.floor(Math.min(window.innerWidth, (containerRef?.current?.clientWidth ?? 0)) / (columnWidth + 2 * padding)), 1),
    [columnWidth, padding, containerRef],
  )

  const [columnCount, setColumnCount] = useState(getColumnCount())

  const handleResize = useCallback(
    () => {
      setColumnCount(getColumnCount())
    },
    [getColumnCount],
  )
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  const mediaMetrics = useMemo(() => {
    const mediaMetrics = Array.from({ length: columnCount }, (_, i) => i + 1).map((): Array<JSX.Element> => []);
    const heap = new Heap<WaterFallColumn>((obj1, obj2) => obj1.height - obj2.height);
    mediaMetrics.map((items) => heap.add({ items, height: 0 }))


    for (let i = 0; i < medias.length; i++) {
      const media = medias[i];
      const column = heap.pop() as WaterFallColumn;
      let height, item;

      if (typeof media === 'string') {
        height = media.length * 1.25; // estimated 1.25
        item = <span style={{ fontSize: 20 }}>{media}</span>
      }
      else {
        height = media.height * (columnWidth / media.width)
        item = <div>
          <Title style={{ marginTop: 10 }} level={3}>{media.date.toLocaleString()}</Title><Image
            src={media.baseUrl}
            width={columnWidth}
            style={{ borderRadius: padding * 2, marginBottom: -6 }}
            onClick={() => window.open(media.productUrl, '_blank')} />
        </div>;
      }

      column.items.push(item);
      heap.add({ height: column.height + height, items: column.items })
    }
    return mediaMetrics;
  }, [columnCount, columnWidth, medias, padding])

  const tryLoadMore = useCallback(
    () => {
      const { scrollHeight, clientHeight, scrollTop } = containerRef.current;
      if (clientHeight + scrollTop + loadingPosition < scrollHeight || (clientHeight + scrollTop === scrollHeight && scrollTop !== 0)) {
        return;
      }
      load();
    },
    [load, loadingPosition],
  )

  const tryScrollDown = useCallback(
    () => {
      const { scrollHeight, clientHeight, scrollTop } = containerRef.current;
      if (clientHeight + scrollTop + loadingPosition * 3 < scrollHeight) {
        return;
      }
      containerRef.current.scrollTo({ top: scrollHeight - clientHeight - 1, behavior: 'smooth' })
    },
    [loadingPosition],
  )

  useEffect(() => {
    const loadMore = setInterval(tryLoadMore, loadInterval)
    const scrollDown = setInterval(tryScrollDown, scrollInterval)
    return () => {
      clearInterval(loadMore)
      clearInterval(scrollDown)
    }
  }, [loadInterval, scrollInterval, tryLoadMore, tryScrollDown])

  return <div ref={containerRef} style={{ overflowY: 'scroll', height: '100%' }}>
    <Title style={{ marginTop: 10 }} level={1}>{title}</Title>
    <FlexLayout align='start' justify='around'>
      {
        mediaMetrics.map((mediaList, index) =>
          <FlexLayout key={index} direction='vertical' style={{ width: columnWidth + 2 * padding }} >
            {mediaList.map((media, idx) => (
              <div style={{
                marginTop: 2 * padding,
                backgroundColor: 'rgba(255,255,255,0.8)',
                border: 'solid #000',
                borderColor: 'white',
                borderWidth: padding,
                borderRadius: padding * 3
              }} key={idx}>
                {media}
              </div>))}
          </FlexLayout>
        )
      }
    </FlexLayout>
  </div>;
}

export default WaterFall;