import { useGoogleApi } from './react-gapi'
import MediaProvider from './MediaProvider';
import bgi from './bgi.jpeg';

const Container = () => {
  const gapi = useGoogleApi({ discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/photoslibrary/v1/rest'], scopes: ['https://www.googleapis.com/auth/photoslibrary.readonly'] })
  const auth = gapi?.auth2?.getAuthInstance()

  if (!auth || !gapi) {
    return <div>Loading...</div>
  }

  return <div style={{
    height: '100vh',
    backgroundImage: `url(${bgi})`,
    backgroundSize: '80%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: 'rgb(255,213,214)',
  }} >
    {auth.currentUser?.get()?.getBasicProfile() ? <MediaProvider gapi={gapi} /> : <button onClick={() => auth && auth.signIn()}>Login</button>}
  </div>
}

export default Container;