import { useCallback, useMemo, useState } from "react";
import { gapiObject } from "./react-gapi";
import BGM from './BGM';
import WaterFall, { Media } from "./WaterFall";

type Props = {
  gapi: gapiObject
}

const COLUMN_WIDTH = 500;
const PADDING = 10;

const STARTING_YEAR = 2016;
const PAGE_SIZE = 10;
const LOADING_POSITION = 1600;
const LOAD_INTERVAL = 1 * 1000;
const SCROLL_INTERVAL = 15 * 1000;
const NEXT_DATES = 23;

const MAX_MEDIA_SIZE = 500;
const MIN_MEDIA_SIZE = 100;

function getRandomSubarray<T>(arr:Array<T>, size: number) {
  var shuffled = arr.slice(0), i = arr.length, temp, index;
  while (i--) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(0, size);
}

const MediaProvider = ({ gapi }: Props) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [medias, setMedias] = useState<Array<Media | string>>([])

  const fetchMedias = useCallback(
    () => {
      const getMedia = gapi.client?.photoslibrary?.mediaItems;
      const rand = Math.sqrt(Math.random());
      const randYear = Math.floor(rand * ((new Date()).getFullYear() - STARTING_YEAR + 1)) + STARTING_YEAR;
      const startDate = new Date(currentDate);
      startDate.setFullYear(randYear)
      const endDate = new Date(startDate.getTime() + NEXT_DATES * 24 * 3600 * 1000);
      
      const dateRange = {
        startDate: {
          year: startDate.getFullYear(),
          month: startDate.getMonth() + 1,
          day: startDate.getDate()
        },
        endDate: {
          year: endDate.getFullYear(),
          month: endDate.getMonth() + 1,
          day: endDate.getDate()
        }
      };
      
      getMedia?.search({
        pageSize: 100,
        filters: {
          contentFilter: {
            includedContentCategories: [
              'PEOPLE', 'SELFIES'
            ]
          },
          mediaTypeFilter: {
            mediaTypes: [
              "PHOTO"
            ]
          },
          dateFilter: {
            ranges:[dateRange]
          }
        },
      }).then((a: any) => {
        const arr = (a?.result?.mediaItems ?? []).map(
          (item: any):Media => ({ 
            baseUrl: item.baseUrl, 
            productUrl: item.productUrl,
            width: Number.parseInt(item.mediaMetadata.width),
            height: Number.parseInt(item.mediaMetadata.height),
            date: new Date(item.mediaMetadata.creationTime),
          }));
          
        let mediasArr = [...medias, ...getRandomSubarray<Media>(arr, PAGE_SIZE)];
        if (mediasArr.length > MAX_MEDIA_SIZE){
          mediasArr = mediasArr.slice(mediasArr.length - MIN_MEDIA_SIZE);
        }
        setMedias(mediasArr);
        setCurrentDate(new Date(currentDate.getTime() - NEXT_DATES * 24 * 3600 * 1000));
      }).catch((e: any) => console.log('err', e));
    },

    [currentDate, gapi.client?.photoslibrary?.mediaItems, medias],
  )

  const waterfall = useMemo(() => <WaterFall
    title=""
    medias={medias}
    columnWidth={COLUMN_WIDTH}
    padding={PADDING}
    loadingPosition={LOADING_POSITION}
    load={fetchMedias}
    loadInterval={LOAD_INTERVAL}
    scrollInterval={SCROLL_INTERVAL}
  />, [fetchMedias, medias])

  return <div style={{height:'100%'}}>
    <BGM path={'2021-12-09'} />
    {waterfall}
  </div>
}

export default MediaProvider;