import { Button } from 'antd';
import { useState } from 'react';
import { GoogleApiProvider } from './react-gapi'
import Container from './Container';

const Content = () => {
  const [open, setOpen] = useState(false)

  return <GoogleApiProvider
    clientId='259584434803-u96ltu85bbhglmvulf2orsl5bemla0og.apps.googleusercontent.com'>
    {open ? <Container /> : <Button
      onClick={() => setOpen(true)}
      style={{
        width: "100%",
        height: "100vh",
        fontSize: 128
      }}>开始</Button>}
  </GoogleApiProvider>;
}

export default Content;