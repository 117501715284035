import firebase from "firebase"
import { useCallback, useEffect, useMemo, useState } from "react"
import Sound from 'react-sound';

type Props = {
  path: string,
}

const BGM = ({path}:Props) => {
  const [songList, setSongList] = useState<Array<string>>([])
  const [songUrl, setSongUrl] = useState<string>('')

  const chooseSound = useCallback(
    (list: Array<string>) => {
      const idx = Math.floor(Math.random() * list.length)
      setSongUrl(list[idx]);
    },
    [],
  )

  useEffect(() => {
    firebase.storage().ref(path).listAll().then((resp) => {
      Promise.all(resp.items.map(item => item.getDownloadURL())).then(resp => {
        setSongList(resp);
        chooseSound(resp);
      })
    });
  }, [chooseSound, path])

  const playNextSound = useCallback(
    () => {
      chooseSound(songList);
    },
    [chooseSound, songList],
  )
  
  const sound = useMemo(() => songUrl ? <Sound
    url={songUrl}
    playStatus={'PLAYING'}
    onFinishedPlaying={playNextSound}
  /> : <div />, [playNextSound, songUrl])

  return <div>{sound}</div>
}

export default BGM